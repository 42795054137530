import { ArrowBack, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton,Tooltip } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { postSubmit } from 'global/postFunc';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';

const TambahPj = () => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));
    
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        nama: '',
        no_hp: '',
    })

    useEffect(() => {
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const submit = (event) => {
        event.preventDefault();
        if (formData.nik.length !== 16) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "NIK harus 16 digit",
              });
              return
        }
        setLoading(true)
        addReq()
        postSubmit(
            '/entry/suara-tambahan/insert-pj',
            formData,
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        navigate(-1)
    }

    return (
        <SoftBox mb={3}>
            <Grid container spacing={3} justifyContent='center' py={5}>
                <Grid item xs={12} sm={12} md={9}>
                    <Card >
                        <CardHeader
                            title={
                                <SoftTypography variant='h6'>Kembali</SoftTypography>
                            }
                            avatar={
                                <Tooltip title="Kembali">
                                    <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                </Tooltip>
                            }
                        />
                        {
                            loading ?
                                (
                                    <Grid container spacing={3} py={5} justifyContent="center">
                                        <Grid item>
                                            <Box sx={{ display: 'flex' }}>
                                                <CircularProgress />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                                :
                                (
                                    <CardContent component='form' onSubmit={submit}>
                                        <Grid container spacing={3} justifyContent="center">
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <SoftBox mb={2} justifyContent="center">
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Nama
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="text"
                                                        defaultValue={formData.nama}
                                                        onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                        autoComplete='off'
                                                        required
                                                    />
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} justifyContent="center" mb={1}>
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <SoftBox mb={2} justifyContent="center">
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            No. HP
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="text"
                                                        value={formData.no_hp}
                                                        onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                        autoComplete='off'
                                                        required
                                                        inputProps={{ maxLength: 12 }}
                                                    />
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent='center'>
                                            <Grid item xs={12} sm={12} lg={6}>
                                                <Grid container justifyContent='flex-end'>
                                                    <Grid item>
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <LoadingButton
                                                                loading={loading}
                                                                loadingPosition="start"
                                                                startIcon={<Save />}
                                                                variant="contained"
                                                                type='submit'
                                                            >
                                                                Save
                                                            </LoadingButton>
                                                        </SoftBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                )
                        }

                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default TambahPj